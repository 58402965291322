<template>
  <section>
    <div class="content-header">
      <h2>Sense of belonging and persistence in white and African-American first-year students</h2>
    </div>
    <div class="content-wrapper">
      <p>Do interventions aimed at increasing students’ sense of belonging work for all students?</p>
      <p>In <a href="https://link.springer.com/article/10.1007%2Fs11162-009-9137-8" target="_blank">this paper</a>, the authors found that such an intervention increased sense of belonging for white students, but not for African-American students. </p>
      <p>However, it did conclude that sense of belonging had direct effects on institutional commitment and indirect effects on intentions to persist and actual persistence behaviour for both white and African-American students.</p>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
